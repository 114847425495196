<template>
  <div class="popup-footer-one-button">
    <div class="one-button">
      <base-button-medium-white
        v-if="oneButtonColor === 'white'"
        @click="close"
      >
        {{ text }}
      </base-button-medium-white>
      <base-button-medium-orange
        v-if="oneButtonColor === 'orange'"
        @click="close"
      >
        {{ text }}
      </base-button-medium-orange>
    </div>
  </div>
</template>

<script>
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
export default {
  name: 'PopupFooterOneButton',
  components: {
    BaseButtonMediumWhite,
    BaseButtonMediumOrange
  },
  props: {
    text: { type: String, default: '閉じる' },
    oneButtonColor: { type: String, default: 'white' }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-footer-one-button {
  display: flex;
}
</style>
