import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store'
import { netAvailableFlg } from '@/utils/library'
import { SESSION_STORAGE_KEY } from '@/utils/define'

import LoginTop from '@/components/pages/LoginTop'
const ReservationMenu = () => import('@/components/pages/ReservationMenu')
const ReservationPurpose = () => import('@/components/pages/ReservationPurpose')
const ReservationDateSelect = () =>
  import('@/components/pages/ReservationDateSelect')
const ReservationTimeSelect = () =>
  import('@/components/pages/ReservationTimeSelect')
const ReservationInputPage = () =>
  import('@/components/pages/ReservationInputPage')
const FirstReservationInputPage = () =>
  import('@/components/pages/FirstReservationInputPage')
const RegistrationConfirm = () =>
  import('@/components/pages/RegistrationConfirm')
const SendMailFirstReservation = () =>
  import('@/components/pages/SendMailFirstReservation')
const RegistrationConfirmChangeReservation = () =>
  import('@/components/pages/RegistrationConfirmChangeReservation')
const ReservationRegistered = () =>
  import('@/components/pages/ReservationRegistered')
const ReservationConfirm = () => import('@/components/pages/ReservationConfirm')
const OwnerPatientsInformation = () =>
  import('@/components/pages/OwnerPatientsInformation')
const OwnerPatientsInformationChange = () =>
  import('@/components/pages/OwnerPatientsInformationChange')
const OwnerPatientsInformationConfirm = () =>
  import('@/components/pages/OwnerPatientsInformationConfirm')
const OwnerPatientsInformationCompletion = () =>
  import('@/components/pages/OwnerPatientsInformationCompletion')
const PasswordResetSendMail = () =>
  import('@/components/pages/PasswordResetSendMail')
const PasswordResetSendAfter = () =>
  import('@/components/pages/PasswordResetSendAfter')
import PasswordReset from '@/components/pages/PasswordReset'
const PasswordResetRegistered = () =>
  import('@/components/pages/PasswordResetRegistered')
const ReservationCancelComplete = () =>
  import('@/components/pages/ReservationCancelComplete')
const MaintenanceMode = () => import('@/components/pages/MaintenanceMode')

import { isNgTransition } from '@/utils/transition'
import { handleReload } from '@/utils/handle_reload'

Vue.use(Router)

export const routes = [
  {
    path: '/login-top',
    name: 'LoginTop',
    component: LoginTop,
    meta: { isPublic: true }
  },
  {
    path: '/reservation-menu',
    name: 'ReservationMenu',
    component: ReservationMenu
  },
  {
    path: '/reservation-purpose',
    name: 'ReservationPurpose',
    component: ReservationPurpose,
    meta: { isPublic: true, isNetReservationPages: true }
  },
  {
    path: '/reservation-date-select',
    name: 'ReservationDateSelect',
    component: ReservationDateSelect,
    meta: { isPublic: true, isNetReservationPages: true }
  },
  {
    path: '/reservation-time-select',
    name: 'ReservationTimeSelect',
    component: ReservationTimeSelect,
    meta: { isPublic: true, isNetReservationPages: true }
  },
  {
    path: '/reservation-input-page',
    name: 'ReservationInputPage',
    component: ReservationInputPage,
    meta: { isNetReservationPages: true }
  },
  {
    path: '/first-reservation-input-page',
    name: 'FirstReservationInputPage',
    component: FirstReservationInputPage,
    meta: { isPublic: true, isNetReservationPages: true }
  },
  {
    path: '/registration-confirm',
    name: 'RegistrationConfirm',
    component: RegistrationConfirm,
    meta: { isPublic: true, isNetReservationPages: true }
  },
  {
    path: '/send-mail-first-reservation',
    name: 'SendMailFirstReservation',
    component: SendMailFirstReservation,
    meta: { isPublic: true, isNetReservationPages: true }
  },
  {
    path: '/reservation-registered',
    name: 'ReservationRegistered',
    component: ReservationRegistered,
    meta: { isPublic: true }
  },
  {
    path: '/reservation-confirm',
    name: 'ReservationConfirm',
    component: ReservationConfirm
  },
  {
    path: '/owner-patients-information',
    name: 'OwnerPatientsInformation',
    component: OwnerPatientsInformation
  },
  {
    path: '/owner-patients-information-change',
    name: 'OwnerPatientsInformationChange',
    component: OwnerPatientsInformationChange
  },
  {
    path: '/owner-patients-information-confirm',
    name: 'OwnerPatientsInformationConfirm',
    component: OwnerPatientsInformationConfirm
  },
  {
    path: '/owner-patients-information-completion',
    name: 'OwnerPatientsInformationCompletion',
    component: OwnerPatientsInformationCompletion
  },
  {
    path: '/password-reset-send-mail',
    name: 'PasswordResetSendMail',
    component: PasswordResetSendMail,
    meta: { isPublic: true }
  },
  {
    path: '/password-reset-send-after',
    name: 'PasswordResetSendAfter',
    component: PasswordResetSendAfter,
    meta: { isPublic: true }
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: { isPublic: true }
  },
  {
    path: '/password-reset-registered',
    name: 'PasswordResetRegistered',
    component: PasswordResetRegistered
  },
  {
    path: '/registration-confirm-change-reservation',
    name: 'RegistrationConfirmChangeReservation',
    component: RegistrationConfirmChangeReservation,
    meta: { isNetReservationPages: true }
  },
  {
    path: '/reservation-cancel-complete',
    name: 'ReservationCancelComplete',
    component: ReservationCancelComplete,
    meta: { isNetReservationPages: true }
  },
  {
    path: '/maintenance',
    name: 'MaintenanceMode',
    component: MaintenanceMode,
    meta: { isPublic: true }
  }
]

const router = new Router({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : { x: 0, y: 0 }
  }
})

const getEncryptedClinicId = () => {
  return !process.env.CI ? sessionStorage.getItem('encryptedClinicId') : null
}

export const beforeEach = async (to, from, next) => {
  if (isNgTransition(from, to) && to?.path !== '/maintenance') {
    router.back()
    return
  }

  const ngLeave = store.getters['petorelu/get'].preventLeaveFlg
  if (ngLeave) {
    store.dispatch('petorelu/showLeaveDialog')
    store.dispatch('petorelu/saveNextRoute', next)
    return
  }
  store.dispatch('petorelu/leaveReset')
  const isTokenRequiredPage = to.matched.some(record => !record.meta.isPublic)
  const isNetReservationPage = to.matched.some(
    record => record.meta.isNetReservationPages
  )
  const logined = store.getters['auth/logined']
  const entranceToken = store.getters['auth/entranceToken']
  const storage = !process.env.CI
    ? JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY))
    : { auth: { token: 'token' } }
  if (isTokenRequiredPage && logined === false && entranceToken === '') {
    const res = await handleReload(storage.auth.token)
    const encryptedClinicId = getEncryptedClinicId()
    res
      ? next({ path: '/reservation-menu' })
      : next({
          path: '/login-top',
          query: { clinic: encryptedClinicId }
        })
  } else if (
    entranceToken !== '' &&
    store.getters['auth/scenario'] === 'first_reservation' &&
    to.path === from.path
  ) {
    const encryptedClinicId = getEncryptedClinicId()
    next({
      path: '/login-top',
      query: { clinic: encryptedClinicId }
    })
  } else if (isNetReservationPage && !netAvailableFlg()) {
    const res = await handleReload(storage.auth.token)
    const encryptedClinicId = getEncryptedClinicId()
    res
      ? next({ path: '/reservation-menu' })
      : next({
          path: '/login-top',
          query: { clinic: encryptedClinicId }
        })
  } else {
    next()
  }
}

router.beforeEach((to, from, next) => beforeEach(to, from, next))

export default router
